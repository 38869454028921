.react-icons svg {
  font-size: 20px;
}
.checkout-dropdown .dropdown-toggle,
.checkout-dropdown .dropdown-toggle:focus {
  width: 100%;
  background-color: transparent !important;
  font-size: 13.8667px;
  line-height: 16px;
  color: #000000 !important;
  border: 1px solid #f5f5f8 !important;
  border-radius: 8px;
  text-align: left;
  position: relative;
  box-shadow: none !important;
  padding: 24px;
}
.checkout-dropdown .dropdown-toggle::after {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  border-top-color: #00983d;
}
.checkout-dropdown .dropdown-menu {
  width: 100%;
  max-height:300px;
  overflow-y: auto;
}
.time-slot-dropdown{
  display: flex !important;
  flex-wrap: wrap;
  padding: 0px !important;
}
.time-slot-dropdown .dropdown-item {
  width: 50%;
  font-size: 13px;
  text-align: center;
  border-top: 1px solid #f5f5f5;
  border-left: 1px solid #f5f5f5;
  padding: 0.6rem;
  white-space: normal !important;
}
.time-slot-dropdown .dropdown-item:hover{
  background-color: rgba(0, 152, 61, 0.1) !important;
}
.required {
  color: red;
  font-size: 10px;
  margin: 0px 5px;
}
.checkout-dropdown-small .dropdown-toggle,
.checkout-dropdown-small .dropdown-toggle:focus {
  width: 100%;
  font-size: 13.8667px;
  line-height: 16px;
  color: #000000 !important;
  border: 0px !important;
  background-color: rgba(187, 189, 193, 0.18) !important;
  border-radius: 8px;
  text-align: left;
  position: relative;
  box-shadow: none !important;
  padding: 12px 8px;
}
.checkout-dropdown-small .dropdown-toggle::after {
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  border-top-color: #00983d;
}
.checkout-dropdown-small .dropdown-menu {
  width: 100%;
}
.address-text {
  height: 100px;
}
.mastercard {
  box-sizing: content-box;
  width: 25px;
  height: 16px;
  background: transparent url("../../assets/images/master-card.svg") no-repeat center;
}
.payment-label {
  position: relative;
  cursor: pointer;
}
.cod-icon {
  font-size: 24px;
  color: #546e7a;
}
.order-item-img {
  max-width: 50px;
  max-height: 50px;
}
/* .time-slot-dropdown{
  display: flex;
} */
