.mainbar {
  border-bottom: 2px solid #f4f6f8;
  /* padding: 10px 0px; */
  margin: 0px auto;
}
.logo {
  width: 137px;
  transform: translateX(-9px);
}
.new-logo {
  height: 70px;
}
.location-wrap {
  display: flex;
  font-size: 14px;
  align-items: center;
  text-transform: capitalize;
  margin: 0px 20px;
}
.location-wrap span {
  text-align: right;
}
.location-wrap::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: no-repeat center url("../../assets/images/Down.svg");
  align-self: flex-start;
  margin-left: 4px;
}
.location-name {
  font-size: 14px;
  color: #494b4b;
  font-weight: bold;
}
.location-name .location-icon {
  font-size: 16px;
  margin-top: -2px;
  margin-right: 4px;
}
.location-name .store-name {
  font-weight: normal;
  font-size: 13px;
  color: #555;
}
.search-wrap {
  flex-grow: 1;
  background: #f2f2f2;
  border-radius: 8px;
  margin: 0px 10px;
  display: flex;
  max-width: 600px;
}
.search-wrap-svg {
  color: #00983d;
  font-size: 20px;
}
.search-wrap-icon {
  padding: 10px 15px;
  user-select: none;
}
.search-wrap-input {
  background-color: transparent;
  border: 0px;
  flex-grow: 1;
  font-size: 14px;
  padding: 5px 20px;
  outline: none;
}
.search-wrap-input:focus {
  outline: none;
}
.header-icons-wrap, .header-icon-pc-mob {
  /* padding: 10px; */
  /* margin: 0px 5px; */
  width:40px;
  height:40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-icons {
  font-size: 24px;
}
.user-wrap {
  background-color: transparent;
  padding: 5px 0px;
  margin: 0px 50px;
  border: 0px;
  margin: 0px 30px;
}
.mobile-menu {
  display: none;
}
.mobile-menu.ham-menu {
  width:30px;
  padding:8px 0px 8px 0px;
}
.mobile-menu-overlay {
}
.cart-loading {
  animation: border-anim 1s linear infinite;
  border: 2px solid transparent;
  border-radius: 50%;
}
@keyframes border-anim {
  0% {
    border: 2px solid transparent;
  }
  50%{
    border: 2px solid #00983d;
  }
  100% {
    border: 2px solid transparent;
  }
}
@media only screen and (max-width: 768px) {
  .new-logo {
    width: 50px;
    height: auto;
    margin: 5px 0px;
  }
  .search-wrap {
    order:1;
    width:100%;
    min-width: 100%;
    margin: 0px 0px 10px 0px;
  }
  .search-wrap-input {
    width: 100%;
  }
  .header-icons-wrap,
  .location-wrap,
  .user-wrap.mobile-view {
    display: none !important;
  }
  .header-icons-wrap.mobile-menu {
    display: block !important;
  }
}
@media only screen and (max-width:1024px){
  .user-actions-wrap{
    margin-left:auto;
  }
}


.main-search-box {
  background-color: #f9fafb;
  border-radius: 4px;
  flex-grow: 1;
  max-width: 512px;
}
.category-box {
  font-size: 14px;
  color: #161d25;
  padding: 8px 16px;
}
.category-box::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  /* background: no-repeat center url("../../assets/images/expand.svg"); */
  border-right: 1px solid #ccc;
  margin-top: 2px;
  margin-left: 5px;
}
.main-search-input {
  border: 0px;
  padding: 5px;
  font-size: 14px;
  background-color: #f9fafb;
  flex-grow: 1;
  outline: none;
}
.main-search-button {
  outline: none;
  width: 44px;
  height: 44px;
  color: white;
  border-radius: 4px;
  border: 1px solid #f9fafb;
  background: #00983d url("../../assets/images/Search.svg") no-repeat center;
}
.user-action-box {
  margin-left: 20px;
}
.user-action-button {
  width: 48px;
  height: 48px;
  border: 1px solid #f4f6f8;
  border-radius: 50%;
  background: transparent no-repeat center;
  margin-right: 15px;
  cursor: pointer;
}
.bell {
  background-image: url("../../assets/images/bell.svg");
}
.cart {
  background-image: url("../../assets/images/cart.svg");
}
.user {
  background-image: url("../../assets/images/user.svg");
}
.heart {
  background-image: url("../../assets/images/heart.svg");
}
.user-action-indicator {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 19px;
  height: 19px;
  background-color: #de3618;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  color: white;
}
.user-account-label {
  font-size: 16px;
  color: #637381;
  margin: 0px 0px 5px 0px;
}
.user-account-button {
  font-weight: bold;
}
.user-account-button::after {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: no-repeat center url("../../assets/images/Down.svg");
  margin-top: 2px;
}

.highlight {
  background-color: white;
}
.highlight:hover {
  background-color: #f5f5f5;
}
.account-wrap {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  background-color: white;
  padding: 6px 15px;
  font-size: 15px;
  font-weight: 500;
  min-width: 160px;
}
.arrow-rotate {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}
.arrow-rotate.spin90 {
  transform: rotate(90deg);
}
.arrow-rotate.spin180 {
  transform: rotate(180deg);
}
.account-popover {
  border: 0px !important;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px !important;
  overflow: hidden;
  margin-top: 0px !important;
  min-width: 130px !important;
}
.account-popover .arrow,
.account-popover .arrow::before,
.account-popover .arrow::after {
  border: 0px !important;
  height: 0px !important;
}

/* 
button.user-action-button.cart.position-relative.cart-icon {
  border: 2px solid #00983d
} */
button.icon.position-relative {
  border-radius: 50%;
  border: 1px solid #f4f6f8;
  background: none;
  color: grey;
  padding: 10px 15px;
  font-size: 20px;
  margin: 5px;
}
button.icon.position-relative.selected {
  border: 1px solid #00983d;
  color: #00983d;
}
.selected {
  /* border: 1px solid #00983d; */
  color: #00983d;
}
a.icon.position-relative {
  border-radius: 50%;
  border: 1px solid #f4f6f8;
  background: none;
  color: grey;
  padding: 10px 15px;
  font-size: 20px;
  margin: 5px;
}
a.icon.position-relative.selected {
  border: 1px solid #00983d;
  color: #00983d;
}
