.top-footer {
  padding-bottom: 20px;
  border-bottom: 1.10133px solid rgba(0, 0, 0, 0.08);
}
.footer-company-cards {
  padding: 20px;
  border: 1px solid #f4f6f8;
  border-radius: 8px;
}
/* .footer-company-card-icon {
  width: 28px;
  height: 28px;
  background: transparent url("../../assets/images/truck.svg") no-repeat center;
} */
.footer-company-card-title {
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #161d25;
  margin: 15px 0px 0px 0px;
}
.footer-company-card-info {
  font-size: 14px;
  line-height: 24px;
  color: #959ead;
  margin: 8px 0px 0px 0px;
}
.footer-app-download {
  width: 100%;
  height: 50px;
  max-width: 200px;
  border-radius: 4px;
  border: 0px;
}
.googleplay {
  background: #000 url("../../assets/images/gplay.svg") no-repeat center;
}
.appstore {
  background: #000 url("../../assets/images/applestore.svg") no-repeat center;
  margin-bottom: 20px;
}
.banner-store {
  border: 1px solid #ccc;
  position: relative;
  height: 100%;
  overflow: hidden;
  background: linear-gradient(111deg, #c71712 45%, transparent 45.25%);
}
.bannerPhone-one {
  position: absolute;
  left: 15%;
  bottom: 0px;
}
.bannerPhone-two {
  position: absolute;
  left: 5%;
  bottom: 0px;
  z-index: 0;
}
.banner-store-logo {
  height: 60px;
}
.banner-store-heading {
  font-size: 20px;
  color: #000000;
}
.banner-store-heading span {
  color: #c71712;
  font-weight: bold;
}
/* MIDDLE FOOTER */
.middle-footer {
  padding: 20px 0px;
  border-bottom: 1.10133px solid rgba(0, 0, 0, 0.08);
}
.middle-footer-label {
  margin: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.177778px;
  color: #161d25;
}
.middle-footer-subscribe-input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #f4f6f8;
  border-radius: 8px;
  width: 320px;
  margin-right: 20px;
}
.middle-footer-subscribe-button {
  background-color: #00983d;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  padding: 10px 30px;
  border: 0px;
}
.middle-footer-social-links {
  display: block;
  width: 32px;
  height: 32px;
  background: transparent;
  margin-right: 15px;
  border: 0px;
}
.middle-footer-social-links:last-child {
  margin-right: 0px;
}
.middle-footer-info {
  margin: 0px;
  font-weight: 500;
  font-size: 14px;
  color: #494b4b;
  padding: 5px 15px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  margin-right:10px;
}
/* FAT-FOOTER */
.fat-footer {
  /* padding-top: 30px; */
}
.fat-footer-bg {
  background-color: #ffcdcb;
  padding: 30px !important;
}
.fat-footer-logo {
  width: 203px;
}
.fat-footer-company-info {
  font-size: 14px;
  line-height: 20px;
  color: #161d25;
}
.fat-footer-list-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.177778px;
  color: #161d25;
  margin: 0px 0px 15px 0px;
}
.fat-footer-list-link {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #161d25;
  margin-bottom: 12px;
  border: 0px;
  background-color: transparent;
}
/* FAT FOOTER BASE */
.fat-footer-base {
  /* background-color: #f5f5f8; */
  background-color: #c71712;
  padding: 15px;
  /* margin-top:40px; */
}
.fat-footer-rights-info {
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  color: white;
}
@media only screen and (max-width:768px){
  .pdp-footer{
    padding-bottom:70px;
  }
  .banner-store{
    /* background: linear-gradient(111deg, #c71712 25%, transparent 25.25%); */
    background: none;
  }
  .bannerPhone-one{
    left:80%;
  }
  .bannerPhone-two{
    left:-25%;
  }
}
/* .scroll-to-top{
  position: fixed;
  bottom:30px;
  right:30px;
  width:45px;
  height:45px;
  border-radius: 500px;
  background-color: #333;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border: 1px solid #eee;
  font-size: 12px;
  z-index:9;
  cursor: pointer;
  transition: transform 0.25s ease;
}
.scroll-to-top:hover{
  transform: translateY(-4px);
} */
/* .scroll-to-info{
  position: absolute;
  font-size: 12px;
  padding:6px 10px;
  border-radius: 20px 0px 0px 20px;
  white-space: nowrap;
  transform: translateX(-60px) translateY(80px);
  transition: all 0.25s ease;
  background-color: #f5f5f5;
  z-index:-1;
  color:#f5f5f5;
  pointer-events: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}
.scroll-to-top:hover .scroll-to-info{
  transform: translateX(-60px) translateY(0px);
  color:#333;
} */

