.carousel-wrapper {
  display: block;
  max-width: 1366px;
  margin: auto;
  padding: auto 1rem auto 1rem !important;
}

@media only screen and (max-width: 768px) {
  .carousel-wrapper {
    padding: 0;
  }
}
