.dynamic-section-product-title {
  font-weight: 325;
  font-size: 0.825rem;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
}

.main-offers .swiper-pagination {
  transform: translateY(15px);
}
.main-offers .swiper-pagination-bullet {
  background-color: lightseagreen;
  width: 12px;
  height: 12px;
}
.main-offers .swiper-pagination-bullet-active {
  background-color: #00983d !important;
}

/* SWIPPER */
.shop-by-cat .swiper-container {
  margin: 0px;
}
.shop-by-cat-title {
  font-weight: bold;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 25px;
  margin-left: 15px;
}
.shop-by-cat .swiper-slide {
  display: block;
  width: auto;
}
.shopbycat-slide {
  display: flex;
  gap: 15px;
  width: 330px;
  max-width: 100%;
  flex-wrap: wrap;
  font-family: "Poppins", sans-serif;
  margin-right: 10px;
}
.shopbycat-box:first-child {
  width: 330px;
  height: 100px;
}
.shopbycat-box {
  width: 100px;
  height: 100px;
  font-size: 10px;
  transition: all 0.1s ease;
  cursor: pointer;
  user-select: none;
  background: #f5f5f8;
  border-radius: 8px;
}
.shopbycat-box:hover {
  transform: scale(0.97);
}
.shopbycat-box-img {
  width: 100%;
}

.dynamicSectionSlider {
  /* background: #e5e5e5; */
  margin-bottom: 1rem;
}

.DynamicSection__product {
  padding: 0.6rem;
  padding-top: 2.06rem;
  background: white;
  margin: auto;
  border: 1px solid #f2f2f2;
  border-radius: 12px;
}
.dynamicSectionSlider__button {
  /* margin-left: 0 !important; */
  background-color: #2d9140;
  font-weight: 700;
  color: white;
  border: none;
  height: 2rem;
  /* width: 7.375rem !important; */
  margin: 1rem auto auto auto !important;
}

.dynamic-slider-heart-icon {
  font-weight: 200;
}
@media only screen and (max-width: 768px) {
  .shopbycat-box:first-child {
    width: 240px;
    height: 70px;
  }
  .shopbycat-box {
    width: 70px;
    height: 70px;
  }
}

@media only screen and (max-width: 768px) {
  .dynamicSectionSlider {
    margin-bottom: 0.5rem;
  }
}

.dynamic-section-title {
  font-weight: bold;
  font-size: 14px;
}
.dynamic-section-title {
  font-weight: 325;
  font-size: 14px;
  line-height: 22px;
}
