.location-header {
  background-color: #ffffff;
  box-shadow: 0px 4px 15px rgba(178, 178, 178, 0.18);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  z-index: 10;
}
.Location-logo {
  height: 70px;
}
.location-signin,
.location-createnew {
  font-weight: 500;
  color: #494b4b;
  background-color: transparent;
  border: 0px;
  font-size: 15px;
}
.location-signin {
  border-right: 1px solid #ccc;
  padding-right: 10px;
  margin-right: 10px;
}
.location-createnew {
  color: #00983d;
}
.location-main {
  margin-top: 70px;
  color: #494b4b;
}
.location-bg-box {
  position: relative;
  height: 699px;
  background: linear-gradient(
    180deg,
    rgba(0, 152, 61, 0) 0%,
    rgba(0, 152, 61, 0.12) 100%
  );
  overflow: hidden;
}
.location-bg-bl,
.location-bg-tr {
  mix-blend-mode: darken;
  pointer-events: none;
}
.location-bg-bl {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.location-bg-tr {
  position: absolute;
  top: -8%;
  right: -8%;
  transform: rotate(180deg);
}
.location-main-heading {
  font-weight: bold;
  font-size: 43.5463px;
  text-align: center;
  margin-top: 90px;
}
.location-main-heading span {
  color: #00983d;
}
.location-sub-text {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}
.location-search-box-parent {
  position: relative;
  width: 550px;
  max-width: 100%;
  margin: auto;
}
.location-search-box {
  padding: 16px 50px 16px 16px;
  background: #ffffff;
  box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
  border: 0px;
  outline: none;
  width: 550px;
  max-width: 100%;
}
.location-search-icon {
  font-size: 24px;
  position: absolute;
  right: 30px;
  transform: translateY(70%);
  z-index: 1;
  padding: 0px !important;
  color: #00983d;
  cursor: pointer;
}
.location-fade-text {
  margin-top: 20px;
  font-weight: 500;
  font-size: 14px;
  color: #b2b2b2;
}
.location-fade-text span {
  color: #00983d;
  text-decoration: underline;
  margin-left: 5px;
  cursor: pointer;
}
.geo-suggest-suggests {
  list-style-type: none;
  padding: 10px 0px;
}

.geo-suggest-suggests-list {
  background-color: #fff;
  padding: 10px 10px;
}
.plane-1 {
  position: absolute;
  top: 0px;
  left: 50%;
  z-index: -1;
  max-width: 50%;
}
.plane-2 {
  position: absolute;
  top: 150px;
  right: -15%;
  z-index: -1;
}
.location-illus-text {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}
.location-illus-subtext {
  font-size: 13px;
  color: #7e7f80;
  max-width: 300px;
  margin: auto;
}
.location-banner {
  background: linear-gradient(
    100.84deg,
    rgba(0, 152, 61, 0.15) -15.57%,
    rgba(0, 152, 61, 0.05) 84%
  );
  position: relative;
  overflow: hidden;
  height: 563px;
}
.red-banner {
  background-color: #c71712;
  position: absolute;
  right: -400px;
  top: 0px;
  width: 1000px;
  height: 1000px;
  transform: rotate(-60deg);
  z-index: -1;
}
.iphone1 {
  position: absolute;
  bottom: 0px;
  right: 25%;
  z-index: -1;
}
.iphone2 {
  position: absolute;
  top: 0px;
  right: 5%;
  z-index: -1;
}
.iphone3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  z-index: -1;
}
.store-wrap {
  background-color: #fff;
  padding: 10px;
  border-radius: 4px;
}
.location-banner-heading {
  font-size: 55px;
  color: #000000;
}
.location-banner-heading span {
  color: #c71712;
  font-weight: bold;
}
.location-banner-sub-text {
  font-size: 18px;
  color: #494b4b;
}
.location-download-text {
  font-weight: 600;
  font-size: 25px;
  color: #010202;
}
.location-download-text span {
  font-weight: normal;
  font-size: 16px;
  color: #333;
}
.store-logo {
  height: 57px;
  margin-right: 20px;
}
.mapview-container {
  background-color: #fff;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .location-banner-heading{
    font-size: 24px;
    margin-top:15px;
  }
  .location-banner-sub-text{
    font-size: 16px;
  }
  .store-logo{
    margin-bottom:15px;
  }
  .plane-2 {
    right: 0px;
  }
  /* .location-bg-box {
    height: 499px;
  } */

  .location-bg-bl {
    width: 300px;
  }
  .location-bg-tr {
    width: 300px;
  }
  .red-banner {
    right: -900px;
    top: 0px;
  }
  .iphone1 {
    width: 300px;
    bottom: 0px;
    right: 25%;
  }
  .iphone2 {
    width: 300px;
    top: -100px;
    right: -50%;
  }
  .iphone3 {
    width: 300px;
    right: -100px;
    bottom: -100px;
  }
}
