.header-wrap {
  /* height: 360px; */
  margin-top: 140px;
}
.header-child-wrap {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 10;
  background-color: white;
}
@media only screen and (max-width:768px) {
  .header-wrap{
    margin-top: 194px;
  }
}
