.delivery-slots {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 6px 10px;
  margin-right: 5px;
  margin-bottom: 3px;
  min-width: 150px;
  cursor: auto;
  user-select: none;
  align-items: center;
}
.delivery-slots-first {
  background-color: rgba(0, 152, 61, 0.25);
}
.delivery-slots-second {
  background-color: rgba(255, 217, 100, 0.25);
}
/* .express{
  background-color: rgb(228, 253, 228);
} */
.delivery-slots-icon {
  width: 31px;
  height: 31px;
  background-size: 14px;
  border-radius: 6px;
  padding: 5px;
  /* background-color: #FFEAB6; */
}
.delivery-slots-icon-pink {
  width: 31px;
  height: 31px;
  background-size: 14px;
  border-radius: 6px;
  padding: 5px;
  /* background-color: #FFDED1; */
}
.delivery-slots-texts {
  margin-left: 7px;
}
.delivery-slots-info {
  margin: 0px;
  font-size: 10px;
  /* color: #A2A2A2; */
  color: #494b4b;
}
.delivery-slots-time {
  margin: 0px;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  font-weight: 700;
}
