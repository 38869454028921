.sidebar {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 11;
  opacity: 0;
  pointer-events: none;
  transition: all 0.2s linear;
}
.sidebar.active {
  opacity: 1;
  pointer-events: all;
}
.sidebar-backdrop {
  position: inherit;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.5);
}
.sidebar-content-right {
  position: inherit;
  top: 0px;
  right: 0px;
  bottom: 0px;
  max-width: 400px;
  width: 80%;
  background-color: white;
  z-index: 13;
  transform: translateX(110%);
  animation: cart-slider-right-reverse 0.2s ease-in-out;
}

.sidebar-content-left {
  position: inherit;
  top: 0px;
  left: 0px;
  bottom: 0px;
  max-width: 500px;
  width: 80%;
  background-color: white;
  z-index: 13;
  transform: translateX(-110%);
  animation: cart-slider-left-reverse 0.2s ease-in-out;
  overflow-y: auto;
}
.sidebar.active .sidebar-content-right {
  transform: translateX(0%);
  animation: cart-slider-right 0.2s ease-in-out;
}

.sidebar.active .sidebar-content-left {
  transform: translateX(0%);
  animation: cart-slider-left 0.2s ease-in-out;
}
.sidebar-header-icon {
  width: 24px;
  height: 24px;
  background: transparent url("../../assets/images/favourite.svg") no-repeat
    center;
}
.sidebar-item-img {
  width: 90px;
}
.sidebar-item-delete {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  /* background-color: #ff4444; */
  background-color: #aaa;
  border: 0px;
}
.sidebar-fixed-box {
  background-color: #f2f2f2;
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.sidebar-item-wrap {
  height: calc(100% - 166px);
  overflow-y: auto;
}

.geo-suggest-input {
  border: 3px solid #00983d !important;
  border-radius: 10px !important;
}
.geo-suggest-input-icon {
  position: absolute;
  left: 0;
  right: 0;
  margin: 1em 0.4em;
  z-index: 1;
  padding: 0px !important;
}
.mobile-menu-content {
  font-size: 15px;
}
.mobile-menu-name {
  color: #00983d;
  background-color: #f5f5f5;
  font-weight: bold;
}
.user-action-indicator-sidebar {
  position: absolute;
  top: 50%;
  right: 15px;
  width: 19px;
  height: 19px;
  background-color: #de3618;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  font-size: 10px;
  color: white;
  transform: translateY(-50%);
}
.cart-button-wrap {
  gap: 10px;
}
@media screen and (max-width: 768px) {
  .cart-button-text {
    font-size: 11px !important;
  }
  .sidebar-item-img {
    width: 60px;
  }
}

@keyframes cart-slider-right {
  0% {
    transform: translateX(110%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes cart-slider-right-reverse {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(110%);
  }
}
@keyframes cart-slider-left {
  0% {
    transform: translateX(-110%);
  }
  100% {
    transform: translateX(0%);
  }
}
@keyframes cart-slider-left-reverse {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-110%);
  }
}
