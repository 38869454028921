.deliverybar {
  border-bottom: 2px solid #f4f6f8;
  padding: 10px 0px;
  margin: 0px auto;
}
.deliverybar-label {
  margin: 0px 20px 0px 0px;
  white-space: nowrap;
}

.deliverybar .swiper-slide {
  display: block;
  width: auto;
}
.delivery-slots-wrap.slide-arrows .swiper-container{
  margin:0px;
}

@media only screen and (max-width:768px){
  .deliverybar-label{
    display:none;
  }
}
